<template>
  <div>
    <!-- Offer -->
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors && !offerData.isValid
      }"
    >
      <BaseTextarea
        label="Hva ønsker du et tilbud på?"
        placeholder="f.eks. kvikkleireutredning eller geoteknisk detaljprosjektering"
        v-model="offerValue"
        @input="stepValidationValue.showErrors ? validOffer() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in offerData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- Documents -->
    <div class="step-group">
      <BaseFileUpload
        label="Relevante dokumenter: f.eks. eksisterende geotekniske datarapporter, brev fra kommune eller NVE (valgfritt)"
        v-model="documentsValue"
        multiple
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import BaseFileUpload from "../components/BaseFileUpload";
import BaseTextarea from "../components/BaseTextarea";

export default {
  components: {
    BaseFileUpload,
    BaseTextarea
  },
  computed: {
    stepValidationValue() {
      return this.$store.state.step3.stepValidation;
    },

    offerData() {
      return this.$store.state.step3.offer;
    },
    offerValue: {
      get() {
        return this.$store.state.step3.offer.value;
      },
      set(value) {
        this.$store.commit("step3/updateOffer", value);
      }
    },

    documentsValue: {
      get() {
        return this.$store.state.step3.documents.value;
      },
      set(value) {
        this.$store.commit("step3/updateDocuments", value);
      }
    }
  },
  methods: {
    ...mapMutations("step3", ["validOffer"])
  },
  beforeCreate() {
    if (!this.$store.state.step1.stepValidation.isValid) {
      this.$router.push({ name: "Step-1" });
    } else if (!this.$store.state.step2.stepValidation.isValid) {
      this.$router.push({ name: "Step-2" });
    }
  }
};
</script>
